@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;700&display=swap');

@layer base {
    body {
        @apply font-[Nunito];
    }
    li {
        @apply px-4;
        @apply font-bold;
        @apply cursor-pointer;
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(94, 186, 15, 0.8),
        hsla(100, 75%, 60%, 0.8)
    );
}